import React from "react";
import { shortenString } from "../../utils";
import Circle from "../../assets/circle.svg";
import AlgoLogo from "../../assets/algorandlogo.svg";
import { ClaimStatusType, State } from "../../types";
import { Spinner } from "react-bootstrap";

const AccountInfo: React.FC<{ state: State }> = ({
  state: { walletAddress, walletAlgos, allocations },
}) => {
  if (!walletAddress || !allocations) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  const rows = [
    {
      name: "Claimed",
      count: allocations.reduce((count, a) => {
        if (a.claimStatus === ClaimStatusType.TRANSFERRED) {
          return (count += 1);
        }
        return count;
      }, 0),
    },
    {
      name: "Requested",
      count: allocations.reduce((count, a) => {
        if (a.claimStatus === ClaimStatusType.REQUESTED) {
          return (count += 1);
        }
        return count;
      }, 0),
    },
    {
      name: "Opted-In",
      count: allocations.reduce((count, a) => {
        if (a.isOptin) {
          return (count += 1);
        }
        return count;
      }, 0),
    },
  ];

  return (
    <div className="middleContainer__accountInfo">
      <div className="accountInfo__address">
        <img src={Circle} alt="circle" />
        <p>{shortenString(walletAddress, 10, 10)}</p>
      </div>
      <div className="accountInfo__rewardsBalance">
        <p>Status</p>
        <hr />
        {rows.map((row, i) => (
          <div key={i} className="whiteBold">
            <p>{row.name}</p>
            <p>{row.count}</p>
          </div>
        ))}
        <div className="whiteBold">
          <p>Algorand Balance</p>
          <p>
            <img style={{ width: "25px" }} src={AlgoLogo} alt="zone-logo" />
            {walletAlgos}
          </p>
        </div>
        <hr />
      </div>
    </div>
  );
};

export default AccountInfo;
