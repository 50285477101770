import React from "react";
import { Spinner } from "react-bootstrap";
import { useApp } from "../../AppContext";
import CloseButton from "../Buttons/CloseButton";
import AllocationRow from "./AllocationRow";

const ClaimNFT: React.FC = () => {
  const {
    state: { allocations, account },
    setState,
  } = useApp();

  const updateAllocations = () => {
    setState({ allocations: allocations });
  };

  return (
    <div className="middleContainer__claimNFT">
      <h3>Claim your NFT</h3>

      {!account ? (
        <AccountNotConnected />
      ) : allocations && allocations.length === 0 ? (
        <NotEligible />
      ) : !allocations ? (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <React.Fragment>
          <h6 style={{ color: "#4DB04A" }}>Congratulations!</h6>
          <hr />
          <div className="middleContainer__claimNFT__allocations">
            {allocations.map((allocation) => {
              return (
                <AllocationRow
                  key={allocation._id}
                  allocation={allocation}
                  account={account}
                  updateAllocations={updateAllocations}
                />
              );
            })}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

const AccountNotConnected = () => {
  return (
    <div className="middleContainer__claimNFT__accountNotConnected">
      Please make sure you are connected with the right account to claim.
      <div>You will receive...</div>
    </div>
  );
};

const NotEligible = () => {
  return (
    <div className="middleContainer__claimNFT__notEligible">
      <p>Sorry! You are currently not eligible.</p>
      <CloseButton
        onClick={() => {
          window.localStorage.clear();
          window.location.reload();
        }}
      />
    </div>
  );
};

export default ClaimNFT;
