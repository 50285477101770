import React from "react";

export const LandingVideo: React.FC = () => {
  return (
    <div className="landingVideo">
      <video
        src="https://metaboundless-assets-public.s3.ap-south-1.amazonaws.com/MetaBoundless_Main_Trailer.mp4"
        autoPlay={true}
        // controls
      />
    </div>
  );
};
