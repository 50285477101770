import { loadStdlib } from "@reach-sh/stdlib";
import WalletConnect from "@reach-sh/stdlib/ALGO_WalletConnect";
import MyAlgoConnect from "@reach-sh/stdlib/ALGO_MyAlgoConnect";
import env from "../env";
import { WalletType } from "../types";

const reach = loadStdlib({
  REACH_CONNECTOR_MODE: env.REACH_CONNECTOR_MODE,
  REACH_DEBUG: env.REACH_DEBUG,
});

export const connectToWallet = async (type: WalletType) => {
  let Fallback = {};
  if (type === WalletType.ALGO_WALLET) {
    Fallback = { WalletConnect };
  } else {
    Fallback = { MyAlgoConnect };
  }
  reach.setWalletFallback(
    reach.walletFallback({
      providerEnv: env.PROVIDER_ENV,
      ...Fallback,
    })
  );
  const account = await reach.getDefaultAccount();
  const balAtomic = await reach.balanceOf(account);
  const balance = reach.formatCurrency(balAtomic, 4);
  return [account, balance];
};
