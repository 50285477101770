
console.log(process.env.REACT_APP_REACH_CONNECTOR_MODE);
const env = {
  BACKEND_URL: process.env.REACT_APP_BACKEND_URL!,
  PROVIDER_ENV: process.env.REACT_APP_PROVIDER_ENV!,
  REACH_CONNECTOR_MODE: process.env.REACT_APP_REACH_CONNECTOR_MODE!,
  REACH_DEBUG: process.env.REACT_APP_REACH_DEBUG!,

  AFK_BURN_ADDRESS: process.env.REACT_APP_BURN_ADDRESS!,

  ALGORAND_INDEXER_URL: process.env.REACT_APP_ALGORAND_INDEXER_URL!,
  ALGORAND_INDEXER_TOKEN: process.env.REACT_APP_ALGORAND_INDEXER_TOKEN || "",
};

export default env;
