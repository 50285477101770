export interface State {
  account?: any;
  walletConnected: boolean;
  walletAddress?: string;
  walletAlgos?: number;
  showWalletChooseModal: boolean;
  allocations?: IAllocation[];
  showLandingPageModal: boolean;

  inputASA: string;
  duplicateFound?: boolean;
  showDuplicateModal: boolean;
}

export type SetStateType = (ob: any) => void;

export enum WalletType {
  MY_ALGO = "my-algo",
  ALGO_WALLET = "algorand-wallet",
}

export enum ClaimStatusType {
  REQUESTED = "REQUESTED",
  TRANSFERRED = "TRANSFERRED",
  ELIGIBLE = "ELIGIBLE",
}

export interface IAllocation {
  _id: string;
  assetId: number;
  claimStatus: ClaimStatusType;
  assetName: string; // AFK - # 3210
  isOptin: boolean;
  assetImage: string;
}
