import React, { useEffect } from "react";
import { apiGetAllocations } from "../api";
import { useApp } from "../AppContext";
import Logo from "../assets/logo.svg";
import ConnectButton from "./Buttons/ConnectButton";
import WalletChooseModal from "./WalletChooseModal";

export const Header: React.FC = () => {
  const {
    state: { walletConnected, walletAddress, showWalletChooseModal },
    setState,
  } = useApp();

  // this use effect fetches allocations from backend
  useEffect(() => {
    const fetchAllocations = async () => {
      const allocations = await apiGetAllocations(walletAddress!);

      setState({
        allocations,
      });
    };
    if (walletAddress) fetchAllocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletAddress]);

  return (
    <React.Fragment>
      <div className="header">
        {/* <AFKHeading size="sm" text="AFK Elephants" /> */}
        <div className="header__logo">
          <img src={Logo} alt="Logo" />
        </div>
        <ConnectButton
          text={walletAddress ? walletAddress : "Connect"}
          onClick={() => setState({ showWalletChooseModal: true })}
          style={walletConnected ? { pointerEvents: "none" } : {}}
          mask={walletAddress ? true : false}
        />
      </div>
      <WalletChooseModal
        show={showWalletChooseModal}
        onHide={() => setState({ showWalletChooseModal: false })}
        setState={setState}
      />
    </React.Fragment>
  );
};

export default Header;
