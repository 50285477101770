export const shortenString = (content: string, start = 4, end = 4) => {
  if (content.length <= start + end) {
    return content;
  }
  return `${content.slice(0, start)}...${content.slice(content.length - end)}`;
};

export const assert = (condition: any, message: string | undefined) => {
  if (!condition) {
    throw message || "Assertion failed";
  }
};
