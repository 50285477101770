import React, { createContext, useContext, useState } from "react";
import { SetStateType, State } from "../types";

const AppStateContext = createContext<
  { state: State; setState: SetStateType } | undefined
>(undefined);

export const initialStateValue: State = {
  account: undefined,
  walletConnected: false,
  walletAddress: undefined,
  walletAlgos: undefined,
  showWalletChooseModal: false,
  allocations: undefined,
  showLandingPageModal: true,
  inputASA: "",
  // duplicateFound: true, // TODO change to undefined
  duplicateFound: undefined,
  showDuplicateModal: false,
};

function AppProvider({ children }: { children: React.ReactNode }) {
  const [state, _setState] = useState(initialStateValue);

  const setState = (ob: any) => {
    _setState((pre) => ({
      ...pre,
      ...ob,
    }));
  };

  const value = { state, setState };
  return (
    <AppStateContext.Provider value={value}>
      {children}
    </AppStateContext.Provider>
  );
}

function useApp() {
  const context = useContext(AppStateContext);
  if (context === undefined) {
    throw new Error("useApp must be used within a AppProvider");
  }
  return context;
}

export { AppProvider, useApp };
