import {
  BrowserRouter as Router,
  Route,
  Routes as Switch,
} from "react-router-dom";
import LandingPage from "./components/LandingPage";

function AppRouter() {
  return (
    <Router>
      <Switch>
        <Route path="/" element={<LandingPage />} />
        {/* <Route path="/analyzer" element={<DuplicateAnalyzer />} /> */}
      </Switch>
    </Router>
  );
}

export default AppRouter;
