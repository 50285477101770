import React from "react";
import { Modal } from "react-bootstrap";
import MyAlgoLogo from "../assets/myalgo.svg";
import PeraLogo from "../assets/peralogo.png";
import { connectToWallet } from "../services/reach";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { SetStateType, WalletType } from "../types";

const WalletName: React.FC<{
  logo: any;
  text: string;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}> = ({ logo, text, onClick }) => {
  return (
    <div className="walletName" onClick={onClick}>
      <div>
        <img src={logo} alt="wallet" className="walletLogo" />
        <p>{text}</p>
      </div>
    </div>
  );
};

const WalletChooseModal: React.FC<{
  setState: SetStateType;
  show: boolean;
  onHide: () => void;
}> = ({ setState, show, onHide }) => {
  const [selectedWallet, setSelectedWallet] = useState<
    WalletType | undefined
  >();

  useEffect(() => {
    const connect = async () => {
      try {
        const [account, balance] = await connectToWallet(selectedWallet!);

        setState({
          account: account,
          walletAlgos: balance,
          walletAddress: account.networkAccount.addr,
          showWalletChooseModal: false,
          walletConnected: true,
          walletType: selectedWallet,
        });
      } catch (err) {
        console.log(err);
        toast.error("Cannot connect to wallet");
      }
    };
    if (selectedWallet) connect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWallet]);
  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        contentClassName="walletChooseModal"
      >
        <h2 style={{ margin: "10px auto" }}>Select wallet</h2>
        <Modal.Body className="walletChooseModal__body">
          <WalletName
            onClick={() => setSelectedWallet(WalletType.MY_ALGO)}
            logo={MyAlgoLogo}
            text="MyAlgo wallet"
          />
          <WalletName
            onClick={() => setSelectedWallet(WalletType.ALGO_WALLET)}
            logo="https://perawallet.s3-eu-west-3.amazonaws.com/media-kit/pera-logomark-white.svg"
            text="Pera wallet"
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default WalletChooseModal;
