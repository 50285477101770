import axios from "axios";
import env from "../env";

const server = axios.create({
  baseURL: env.BACKEND_URL,
});

export const apiGetAllocations = async (address: string) => {
  const { data } = await server.get(`allocation/${address}`);
  return data;
};

export const apiClaimAllocation = async (allocationId: string) => {
  await server.patch(`allocation/${allocationId}/claim/`);
};

export const apiOptinAllocation = async (allocationId: string) => {
  await server.patch(`allocation/${allocationId}/optin`);
};

export const apiCheckDuplicate = async (assetId: string) => {
  const { data } = await server.get<{ isDuplicate: boolean }>(
    `asset/check/${assetId}`
  );
  return data.isDuplicate;
};
