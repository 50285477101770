import React from "react";
import { Button } from "react-bootstrap";
import Check from "../../assets/check.svg";

const style: React.CSSProperties = {
  backgroundColor: "none",
  border: "1px solid #fcc601",
  color: "white",
};

const RequestedButton: React.FC = () => {
  return (
    <Button
      style={style}
      variant="outlined"
      className="d-flex align-items-center justify-content-center allocationRow__button buttonDisabled"
    >
      <img src={Check} alt="check" />

      <span style={{ marginLeft: "10px" }}>Requested...</span>
    </Button>
  );
};

export default RequestedButton;
