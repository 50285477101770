import React from "react";
import Button from "./Button";

interface Props {}

const LogoutButton: React.FC<Props> = () => {
  return (
    <div>
      <Button
        onClick={() => {
          window.localStorage.clear();
          window.location.reload();
        }}
        variant="outline-dark"
        className="logoutButton"
      >
        Log Out
      </Button>
    </div>
  );
};

export default LogoutButton;
