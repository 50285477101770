import Button from "./Button";

export default function BlueishButton({ onClick, text, ...props }) {
  return (
    <Button
      onClick={onClick}
      variant="filled"
      className="blueishButton allocationRow__button"
      {...props}
    >
      {text}
    </Button>
  );
}
