import { useApp } from "../AppContext";
import BackgroundVideo from "../assets/background.mp4";
import ConnectButton from "./Buttons/ConnectButton";
import Header from "./Header";
import AccountInfo from "./MiddleContainer/AccountInfo";
import ClaimNFT from "./MiddleContainer/ClaimNFT";
import { LandingVideo } from "./MiddleContainer/LandingVideo";
import MiddleContainer from "./MiddleContainer/MiddleContainer";
import "./styles/LandingPage.css";

function LandingPage() {
  const { state, setState } = useApp();

  return (
    <div className="landingPage">
      <video src={BackgroundVideo} autoPlay muted loop></video>
      <Header />
      <MiddleContainer>
        {!state.walletConnected ? (
          <div className="landingPage__video_container">
            <LandingVideo />
            <ConnectButton
              text="Connect Your Wallet"
              onClick={() => setState({ showWalletChooseModal: true })}
              style={
                { width: "200px", marginTop: "2rem" } as React.CSSProperties
              }
              mask={false}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "90%",
              marginTop: "10%",
              flexWrap: "wrap",
            }}
          >
            <AccountInfo state={state} />
            <ClaimNFT />
          </div>
        )}
      </MiddleContainer>

      {/* <LandingModal
        show={state.showLandingPageModal}
        onHide={() => setState({ showLandingPageModal: false })}
      /> */}
    </div>
  );
}

export default LandingPage;
