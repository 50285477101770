import "../styles/Buttons.css";
import Button from "./Button";

function ConnectButton({ text, onClick, mask, ...props }) {
  return (
    <Button {...props} className="connectButton" onClick={onClick}>
      {mask ? `${text.slice(0, 8)}...${text.slice(text.length - 4)}` : text}
    </Button>
  );
}

export default ConnectButton;
