import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import { AppProvider } from "./AppContext";
import AppRouter from "./AppRouter";
import "react-toastify/dist/ReactToastify.css";
import "./components/styles/App.css";

function App() {
  return (
    <div className="App">
      <AppProvider>
        <AppRouter />
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          closeOnClick
          pauseOnHover
          theme="dark"
        />
      </AppProvider>
    </div>
  );
}

export default App;
