import { useApp } from "../../AppContext";
import LogoutButton from "../Buttons/LogoutButton";

const MiddleContainer: React.FC = ({ children }) => {
  const { state } = useApp();
  return (
    <div className="middleContainer">
      {children}
      <div style={{ width: "85%" }}>
        {state.walletConnected && <LogoutButton />}
      </div>
    </div>
  );
};

export default MiddleContainer;
