import React from "react";
import { Button } from "react-bootstrap";
import Check from "../../assets/check.svg";

const style: React.CSSProperties = {
  backgroundColor: "#4DB04A",
  color: "white",
};

export default function ClaimButton({ ...props }) {
  return (
    <Button
      style={style}
      variant="success"
      className="d-flex align-items-center justify-content-center allocationRow__button buttonDisabled"
      {...props}
    >
      <img src={Check} alt="check" />
      <span style={{ marginLeft: "10px" }}>Claimed</span>
    </Button>
  );
}
