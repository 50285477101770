import React from "react";
import { toast } from "react-toastify";
import { apiClaimAllocation, apiOptinAllocation } from "../../api";
import MichaelVideo from "../../assets/Michel_MetaBoundless_NFT_Video.mp4";
import RaghebVideo from "../../assets/Ragheb_MetaBoundless_NFT_Video.mp4";
import SaadVideo from "../../assets/Saad_MataBoundless_NFT_Video.mp4";
import { ClaimStatusType, IAllocation } from "../../types";
import BlueishButton from "../Buttons/BlueishButton";
import ClaimButton from "../Buttons/ClaimButton";
import RequestedButton from "../Buttons/RequestedButton";

interface Props {
  allocation: IAllocation;
  account: any;
  updateAllocations: () => void;
}

const VIDEOS: Record<string, string> = {
  "Ragheb Alama NFT": RaghebVideo,
  "Saad Lamjarred NFT": SaadVideo,
  "Michel Fadel NFT": MichaelVideo,
};

const AllocationRow: React.FC<Props> = ({
  allocation,
  account,
  updateAllocations,
}) => {
  const button = getAssetButton({ allocation, account, updateAllocations });

  return (
    <div className="allocationRow">
      <div className="allocationRow__imageContainer">
        <video muted autoPlay loop src={VIDEOS[allocation.assetName]} />
        <div>
          <p style={{ color: "white", fontWeight: "600" }}>
            {allocation.assetName}
          </p>
          <p style={{ fontSize: "80%" }}>
            <span style={{ color: "#FFCC18" }}>ASA ID </span>
            {allocation.assetId}
          </p>
        </div>
      </div>
      <div>{button}</div>
    </div>
  );
};

const getAssetButton = ({ allocation, account, updateAllocations }: Props) => {
  if (!allocation.isOptin) {
    return (
      <BlueishButton
        onClick={async () => {
          try {
            await account.tokenAccept(allocation.assetId);
            await apiOptinAllocation(allocation._id);
            allocation.isOptin = true;
            updateAllocations();
          } catch (err) {
            console.log(err);
            toast.error("Cannot perform optin transaction");
          }
        }}
        text={`Opt-in`}
      />
    );
  }

  switch (allocation.claimStatus) {
    case ClaimStatusType.ELIGIBLE:
      return (
        <BlueishButton
          text="Request Claim"
          onClick={async () => {
            await apiClaimAllocation(allocation._id);
            allocation.claimStatus = ClaimStatusType.REQUESTED;
            updateAllocations();
          }}
        />
      );
    case ClaimStatusType.REQUESTED:
      return <RequestedButton />;
    case ClaimStatusType.TRANSFERRED:
      return <ClaimButton />;
  }
};

export default AllocationRow;
